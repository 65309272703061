import { createState, PreserveState, Value } from 'terraconnect-state';
import * as TerraconnectUI from 'terraconnect-ui';
import { assign } from './Router';

export interface ChildSwitchProps {
    href: string;
}

const Redirect: TerraconnectUI.ComponentFN<ChildSwitchProps> = function ({ href }) {
    this.addEventListener('mount', () => {
        assign(href);
    });
    return (<></>);
}

export default Redirect as TerraconnectUI.Component<ChildSwitchProps>;

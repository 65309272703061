import { createState, PreserveState, Value } from 'terraconnect-state';
import * as TerraconnectUI from 'terraconnect-ui';

export interface ChildSwitchProps {
  index: unknown;
  nan: number;
}

const ChildSwitch: TerraconnectUI.ComponentFN<ChildSwitchProps> = function ({ children, index, nan }) {
  return (
    createState((children: TerraconnectUI.HTMLComponent<unknown>[], index: unknown, nan: number) => {
      let child = Number.isNaN(index) ? nan : Number(index);
      return children[child] || null;
    }, [children, index, nan])
  );
}

export default ChildSwitch as TerraconnectUI.Component<ChildSwitchProps>;

import { Component, ComponentFN } from 'terraconnect-ui';
import { createState, Modified, Value } from 'terraconnect-state';
import './index.scss';
import Router, { path } from 'Components/Router/Router';
import Route from 'Components/Router/Route';
import RouteNotFound from 'Components/Router/RouteNotFound';
import ChildSwitch from 'Components/ChildSwitch';
import Redirect from 'Components/Router/Redirect';
import { Fragment } from 'terraconnect-ui/jsx-runtime';

let token = createState(window.localStorage.getItem('token'));
window.addEventListener('storage', (event) => {
    if (event.key == 'token') token[Value] = event.newValue;
});
token[Modified].on((value) => {
    if (!value) window.localStorage.removeItem('token');
    else window.localStorage.setItem('token', value);
});

let loggedIn = createState((token: string | null) => token != null, [token]);

const login: ComponentFN = () => {
    return (
        <ChildSwitch index={loggedIn} nan={0}>
            <div className="container">
                <span>Terraconnect Dashboard</span>
                <div className="login-container">
                    <form onSubmit={(e) => {
                        const data = new FormData(e.target as HTMLFormElement);
                        // token[Value] = `${data.get('username')}-${data.get('password')}`;
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <input type='text' placeholder="Username" name="username" id='username' required />
                        <input type='password' placeholder='Password' name="password" id='password' required />
                        <div className="button-container">
                            <button className="login-btn" type='submit'>Login</button>
                            <button className="register-btn">Register</button>
                        </div>
                    </form>
                </div>
            </div>
            <Redirect href='/' />
        </ChildSwitch>
    );
}

const home: ComponentFN = () => {
    return (
        <ChildSwitch index={loggedIn} nan={0}>
            <Redirect href='/login' />
            <div>
                <span>Home</span>
            </div>
        </ChildSwitch>
    );
}

const App: Component = () => {
    return (
        <Router>
            <Route path='/' component={home} />
            <Route path='/login' component={login} />
            <RouteNotFound>
                <span>{createState((path: string) => `Can not find page "${path.slice(1, -1)}"`, [path])}</span>
            </RouteNotFound>
        </Router>
    );
};

export default App;
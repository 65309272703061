// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  user-select: none;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,sBAAA;AACJ;;AAEE;;EAEE,SAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AACJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  height: 100vh;
  background-color: #111;
  color: #fff;
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.container span {
  margin-top: -50px;
  padding-bottom: 50px;
  font-size: 25px;
}

.login-container {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

span {
  font-size: 20px;
  font-weight: bold;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-btn {
  background-color: #007bff;
  color: #fff;
  margin-right: 10px;
}

.register-btn {
  background-color: #1a1a1a;
  /* Ganti warna latar belakang dengan warna card */
  border: 1px solid #0056b3;
  color: #0056b3;
  margin-left: 10px;
}

button:hover {
  background-color: #0056b3;
}

/* Media Query untuk responsif */
@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
  }
  .login-btn,
  .register-btn {
    margin: 10px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/App/index.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,8BAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,iBAAA;EACA,oBAAA;EACA,eAAA;AACR;;AAGA;EACI,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,wCAAA;EACA,YAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,cAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,aAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,aAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iCAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,WAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,iDAAA;EACA,yBAAA;EACA,cAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA,gCAAA;AACA;EACI;IACI,sBAAA;EAAN;EAGE;;IAEI,cAAA;EADN;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
